import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  subscribed = false;
  subscribeForm = this.formBuilder.group({
    name:['',[Validators.required]], 
    email:['',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]], 
    subject:['',[Validators.required]], 
    website : 'ahostingsolutions',
    type : 'contactus',
  });
  private REST_API_SERVER = "https://je3x5so4pg.execute-api.us-east-1.amazonaws.com/default/subscribeNewsletter";
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ){}
  onSubmit(): void{
    const data = this.subscribeForm.value;
    console.log(data);
    this.httpClient.post(this.REST_API_SERVER, data).subscribe((data: any[])=>{
      this.subscribed = true;
      console.log(data);
      this.subscribeForm.reset();
      
    })  
    
  }
  ngOnInit(): void {
  }
}
