import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PricingComponent} from './pricing/pricing.component';
import {ContactusComponent} from './contactus/contactus.component';
import {GalleryComponent} from './gallery/gallery.component';
const routes: Routes = [
  {path: 'home', component: HomeComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'contactus', component: ContactusComponent},
    {path: '', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
