import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public languageLinkText="..";
  public href="";
  public isMenuCollapsed = true;
  constructor(){

  }
  ngOnInit(): void {
    if(window.location.href.indexOf("/es")>=0){
      this.languageLinkText="English";
      this.href="/";
      console.log("es");
    }
    else{
      this.languageLinkText="Español";
      this.href="/es/";
      console.log("not es");
    }
    console.log(window.location.href.indexOf("/es"));
    console.log(window.location.href);
  }
}
