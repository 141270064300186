import { Component, OnInit } from '@angular/core';
import {config} from '../config';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  plans = config.plans;
  
  constructor() { }

  ngOnInit(): void {
  }

}
