import { Component, OnInit } from '@angular/core';
import {config} from '../config';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  templates = config.templates;
  constructor() { }

  ngOnInit(): void {
  }

}
