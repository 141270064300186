<!-- Header-->
<header class="bg-primary py-1 mb-5">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-12">
                <h1 class="display-5 text-white mt-5 mb-2" i18n="@@pricing_section1_title">Demos Gallery <i class="bi bi-window"></i></h1>
                <p class="lead mb-5 text-white-50" i18n="@@pricing_section1_description">There are some of the live demos for the websites that we can customize for your own use. Check them out and let us know what you need.</p>
            </div>
        </div>
    </div>
  </header>
<!-- Page Content-->
<div class="container">
    <div class="row">
        <h2 i18n="@@home_section3_title">Live Demos</h2>
        <div *ngFor="let template of templates" class="col-md-4 mb-5">
          <div class="card h-100">
              <img class="card-img-top" src="{{template.image}}" alt="..." />
              <div class="card-body">
                  <h4 class="card-title">{{template.title}}</h4>
                  <p class="card-text">{{template.description}}</p>
              </div>
              <div class="card-footer" i18n="@@home_section3_card_footer"><a class="btn btn-primary" target="_blank" href="{{template.url}}">Live Demo!</a></div>
          </div>
        </div>
      </div>
 </div>
